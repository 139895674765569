<template>
  <div class="edito">
    <img width="100%" src="../assets/images/bdform.png" />
    <br />
    <br />
    <p>
      Notre offre Quinzaine du Gaz n’a pas encore commencé.<br /><br />
      Rendez-vous le 14/03/2021<br />
    </p>
    <br />
    <br />
  </div>
</template>
<script>
export default {
  name: 'Waiting',
}
</script>
